.ageIconShape {
    margin-left: 50px;
    background: white;
    border: 2px solid black;
    height: 50px;
    text-align: center;
    transform: rotate(45deg);
    width: 50px;
    color: black;
}

.ageIconText {
    display: table-cell;
    height: 50px;
    width: 50px;
    transform: rotate(-45deg);
    vertical-align: middle;
    padding-left: 5px;

    background: inherit;
    -webkit-background-clip: text;
    color: transparent;
    filter: sepia(5) saturate(100) invert(1) grayscale(1) contrast(9);
}